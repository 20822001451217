
// console.log('Got to deals modal javascript');

const offerTemplate = `
<div class="d-flex flex-row align-items-center service-offer">
  <div>
    <svg class="svg-inline--fa fa-dollar-sign fa-w-9 fa-2x me-4" style="width: 14px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dollar-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" data-fa-i2svg=""><path fill="currentColor" d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path></svg><!-- <i class="fas fa-dollar-sign fa-2x me-4" style="width: 14px;"></i> Font Awesome fontawesome.com -->
  </div>
  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
    <div class="mb-4 me-4">
      <label class="form-label" for="services_service_service_offers__amount">Amount</label>
      <input class="form-control p-3" type="number" name="services_service[service_offers][42069][amount]" id="services_service_service_offers__amount">
    </div>
    <div class="mb-4">
      <label class="form-label" for="services_service_service_offers__description">Description</label>
      <input class="form-control p-3" type="text" name="services_service[service_offers][42069][description]" id="services_service_service_offers__description" maxlength="50" size="50">
    </div>
  </div>
  <span class="d-block ms-3 remove-service-offer" role="button"">
    <i class="fas fa-trash-alt color-fanword-text text-decoration-none me-5"></i>
  </span>
</div>
`

const locationTemplate = `
<div class="d-flex flex-row align-items-center service-location">
  <div>
    <svg class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-2x me-4" style="width: 14px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg><!-- <i class="fas fa-map-marker-alt fa-2x me-4" style="width: 14px;"></i> Font Awesome fontawesome.com -->
  </div>
  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
      <div class="mb-4 me-4">
        <label class="form-label" for="services_service_service_locations__city">City</label>
        <input class="form-control p-3" type="text" name="services_service[service_locations][42069][city]" id="services_service_service_locations__city">
      </div>
      <div class="mb-4 me-4">
        <label class="form-label" for="services_service_service_locations__region">State/Region</label>
        <input class="form-control p-3" type="text" name="services_service[service_locations][42069][region]" id="services_service_service_locations__region">
      </div>
      <div class="mb-4">
        <label class="form-label" for="services_service_service_locations__country">Country</label>
        <input class="form-control p-3" type="text" name="services_service[service_locations][42069][country]" id="services_service_service_locations__country">
      </div>
  </div>
  <span class="d-block ms-3 remove-service-location" role="button">
    <i class="fas fa-trash-alt color-fanword-text text-decoration-none me-5"></i>
  </span>
</div>
`

// Your code to run since DOM is loaded and ready
$(function () {

  console.log('Service javascript loaded');

  const modalBody = $("#service-modal .modal-body")

  $('body').on('click', '.service-card-link', function (event) {
    console.log("Got here - service-card-link");

    // find show modal and open it
    var serviceModal = document.getElementById('service-modal')
    const modal = new bootstrap.Modal(serviceModal);
    modal.show();

    const serviceId = $(this).data('serviceId');
    const serviceProviderId = $(this).data('serviceProviderId');
    const title = $(this).data('serviceProviderTitle');

    modalBody.empty();

    $.get(`/services/${serviceId}`, (data) => {
      modalBody.html(data)

      $("#service-contact-button").text(`Contact ${title}`);

      // set data on the "Contact" button that will be used when the contact modal appears
      $("#service-contact-button").attr('data-service-provider-title', title).attr('data-service-provider-id', serviceProviderId);
    });
  });

  // hide/display the locations based on the radio button that is selected
  $("body").on('change', ".service-location-radios", function() {
    if ($(this).val() === "remote") {
      $(".add-service-locations, .add-service-add-location").removeClass('d-block').addClass('d-none');
    } else {
      $(".add-service-locations, .add-service-add-location").removeClass('d-none').addClass('d-block');
    }
  });

  $("body").on("click", ".service-provider-link", function(event){
    event.stopPropagation();
  });

  // hide/display the offers based on the radio button that is selected
  $("body").on('change', ".service-offer-radios", function () {
    if ($(this).val() === "commission") {
      $(".add-service-offers, .add-service-add-offer").removeClass('d-block').addClass('d-none');
    } else {
      $(".add-service-offers, .add-service-add-offer").removeClass('d-none').addClass('d-block');
    }
  });

  $('body').on('click', ".add-service-add-location", function() {
    // this is most definitely the wrong way to do this, but it's like 4 days from Christmas break,
    // the kids have a snow day, and I am tired and want to get this damn ticket done. Will
    // give it to an intern to refactor some day

    let clone = $(".service-location").last().clone();

    if (!clone || !clone[0]) {
      clone = $($.parseHTML(locationTemplate));
    }

    // since we NUKE and re-create all of the locations and offers, these embedded IDs dont
    // really matter. So when we dynamically add a new location or offer,
    // we'll come up with a fake id for it
    const regexPattern = /[0-9]+/
    const fakeId = Math.floor(Math.random() * 100000);

    clone.find('input').each(function() {
      const name = $(this).attr('name').replace(regexPattern, fakeId);
      $(this).attr('name', name).attr('id', name).val('');
    })

    $(".add-service-locations").append(clone);
  });

  $('body').on('click', ".add-service-add-offer", function () {

    // this is copied from above, and idgara
    let clone = $(".service-offer").last().clone();

    if (!clone || !clone[0]) {
      clone = $($.parseHTML(offerTemplate));
    }

    // since we NUKE and re-create all of the locations and offers, these embedded IDs dont
    // really matter. So when we dynamically add a new location or offer,
    // we'll come up with a fake id for it
    const regexPattern = /[0-9]+/
    const fakeId = Math.floor(Math.random() * 100000);

    clone.find('input').each(function () {
      const name = $(this).attr('name').replace(regexPattern, fakeId);
      $(this).attr('name', name).attr('id', name).val('');
    })

    $(".add-service-offers").append(clone);
  });

  // adding or editing modal logic
  $('body').on("click", ".add-service-button", function () {
    const modalBody = $("#add-edit-service-modal .modal-body");

    $.get(`/services/new`, (data) => {
      modalBody.html(data)
    });
  })

  $('body').on("click", ".service-dots-button", function(event) {
    console.log('Got here - service dots button');
    event.stopPropagation();
    event.preventDefault();
  })

  $('body').on("click", ".link-report-button", function (event) {
    event.stopPropagation();
  })

  $('body').on("click", ".edit-service-button", function (event) {
    event.stopPropagation();

    const serviceId = $(this).data('serviceId');
    const modalBody = $("#add-edit-service-modal .modal-body");

    $.get(`/services/${serviceId}/edit`, (data) => {
      modalBody.html(data)
    });
  })

  $(".service-card").on('mouseover', function() {
    console.log('mouserover')
    $(this).find('.service-dots-button').show();
  }).on('mouseout', function() {
    console.log('mouseout')
    $(this).find('.service-dots-button').hide();
  });

  $("body").on('click', '.remove-service-location', function() {
    console.log('Remove location is clicked');

    const locationId = $(this).data('service-location-id');
    const token = $('meta[name="csrf-token"]').attr('content');

    $(this).parent('.service-location').remove();

    // if this location already has been saved,
    // we need to delete it from the database
    if (locationId && token) {
      $.ajaxSetup({
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', token);
        }
      });

      $.ajax({
        url: `/service_locations/${locationId}`,
        type: 'DELETE'
      });
    }

  });

  $("body").on('click', '.remove-service-offer', function () {
    const offerId = $(this).data('service-offer-id');
    const token = $('meta[name="csrf-token"]').attr('content');

    $(this).parent('.service-offer').remove();

    if (offerId && token) {
      $.ajaxSetup({
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', token);
        }
      });

      $.ajax({
        url: `/service_offers/${offerId}`,
        type: 'DELETE'
      });
    }

  });

  // contact modal dynamic template parts
  var contactModal = document.getElementById('contact-service-provider-modal')
  contactModal && contactModal.addEventListener('show.bs.modal', function (event) {
    const button = event.relatedTarget;

    const title = button.getAttribute('data-service-provider-title');
    const id = button.getAttribute('data-service-provider-id');

    if (title) {
      contactModal.querySelector(`#service-provider-modal-title`).textContent = `Contact ${title}`;
    }

    if (id) {
      contactModal.querySelector(`#service-provider-hidden-id`).value = id;
    }
  });

})


